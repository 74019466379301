<template>
	<el-container>
		<el-aside width="200px">
			<div class="headLine">济南大润电子</div>
			<el-menu :router="true" background-color="rgb(29,33,45)" text-color="#fff" active-text-color="#ffd04b"
				:default-active="nowPath" class="el-menu-vertical-demo">
				<el-menu-item index="/home">
					<i class="el-icon-menu"></i>
					<template #title>首页</template>
				</el-menu-item>
				<el-menu-item index="/device/list">
					<i class="el-icon-location"></i>
					<span>设备查询</span>
				</el-menu-item>
				<el-menu-item index="/transaction/list">
					<i class="el-icon-setting"></i>
					<template #title>交易日志</template>
				</el-menu-item>
				<el-menu-item index="/merchant/list">
					<i class="el-icon-setting"></i>
					<template #title>商户管理</template>
				</el-menu-item>
				<el-menu-item index="/number/list">
					<i class="el-icon-setting"></i>
					<template #title>卡号管理</template>
				</el-menu-item>
				<el-menu-item index="/kashang/list">
					<i class="el-icon-setting"></i>
					<template #title>卡商管理</template>
				</el-menu-item>
				<el-menu-item index="/test/mqtt">
					<i class="el-icon-setting"></i>
					<template #title>在线调试</template>
				</el-menu-item>
				<el-menu-item index="/task/list">
					<i class="el-icon-setting"></i>
					<template #title>定时任务</template>
				</el-menu-item>
				<el-menu-item index="/systemLog/list">
					<i class="el-icon-setting"></i>
					<template #title>操作日志</template>
				</el-menu-item>
				
				<!-- <el-submenu index="3">
					<template #title>
						<i class="el-icon-location"></i>
						<span>系统管理</span>
					</template>
					<el-menu-item index="/user/list">
						<template #title>
							<i class="el-icon-location"></i>
							<span>用户管理</span>
						</template>
					</el-menu-item>
					<el-menu-item index="/role/list">
						<template #title>
							<i class="el-icon-location"></i>
							<span>权限管理</span>
						</template>
					</el-menu-item>
				</el-submenu> -->
			</el-menu>
		</el-aside>
		<el-container style="height: 100vh;">
			<el-header style="box-shadow: 0 0 5px rgba(0,0,0,.2);">
				<div style="display: flex;flex-direction: row;height: 100%;">
					<div style="flex-grow: 1;"></div>
					<div style="display: flex;flex-direction: column;justify-content: center; height: 100%; margin-right: 40px;">
						<el-dropdown trigger="click">
							<img style="box-shadow: 0 0 10px rgba(117,117,117,.4); border-radius: 90px; width: 50px; cursor: pointer;"
								src="../assets/touxiang.png" />
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item>账号信息</el-dropdown-item>
									<el-dropdown-item>重置密码</el-dropdown-item>
									<el-dropdown-item>退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
			</el-header>
			<div style="background-color: rgb(245,247,249); display: flex; flex-direction: row; padding: 5px;">
				<div class="left-btn breadcrumb-btn">
					<img style="height: 26px; transform: rotate(180deg);" src="../assets/jiantou.png" />
				</div>
				<div class="tab-list">
					<div @click="toHome" :class="{'action':$route.fullPath == '/home' }" class="tab-item">
						首页
					</div>
					<div @click="redirect(item)" :class="{'action':$route.fullPath == item.href }"
						v-for="item in $store.state.tab" :key="item" class="tab-item">
						{{item.title}}
					</div>
				</div>
				<div class="right-btn breadcrumb-btn">
					<img style="height: 26px;" src="../assets/jiantou.png" />
				</div>
			</div>
			<el-main>
				<transition name="slide-fade">
					<router-view></router-view>
				</transition>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import {
		getCurrentInstance
	} from 'vue'
	import router from '@/router/index.js'
	export default {
		data() {
			return {}
		},

		watch: {
			$route(to, from) {
				const toDepth = to.path.split("/").length;
				const fromDepth = from.path.split("/").length;
				if (to.path == "/") {
					this.direction = "slide-right";
				} else if (from.path == "/") {
					this.direction = "slide-left";
				} else {
					this.direction = toDepth < fromDepth ? "slide-right" : "slide-left";
				}
			}
		},

		setup() {
			const {
				proxy
			} = getCurrentInstance()
			let nowPath = proxy.$root.$route.fullPath
			return {
				nowPath
			}
		},

		methods: {
			toHome: function() {
				router.push({
					path: '/home',
				});
			},

			redirect: function(item) {
				router.push({
					path: item.href,
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.headLine {
		background-color: rgb(29,33,45);
		line-height: 60px;
		height: 60px;
		color: rgb(255, 255, 255);
		box-sizing: border-box;
		text-align: center;
		font-weight: 800;
	}

	.el-header {
		background-color: rgb(255, 255, 255);
		height: 60px;
		color: #333;
		text-align: center;
		line-height: 60px;

	}

	.el-aside {
		background-color: rgb(29,33,45);
		color: #333;
		height: 100vh;
		min-width: 200px;
	}

	.el-main {
		background-color: rgb(255, 255, 255);
		padding: 0;
		box-sizing: border-box;
		padding: 10px;
	}

	.el-menu {
		border: none !important;

	}

	body>.el-container {
		margin-bottom: 40px;
	}

	.el-container:nth-child(5) .el-aside,
	.el-container:nth-child(6) .el-aside {
		line-height: 260px;
	}

	.el-container:nth-child(7) .el-aside {
		line-height: 320px;
	}

	.breadcrumb-btn {
		width: 30px;
		height: 30px;
		text-align: center;
		cursor: pointer;
		display: flex;
		align-items: center
	}

	.tab-item {
		height: 24px;
		background-color: rgb(255, 255, 255);
		color: rgb(85, 161, 242);
		text-align: center;
		width: 90px;
		margin-top: 3px;
		line-height: 24px;
		font-size: 12px;
		cursor: pointer;
		display: inline-block;
		margin-right: 10px;
		border: 1px solid rgba(0, 0, 0, .1);
	}

	.tab-item:hover {
		box-shadow: 0 1px 1px rgba(85, 161, 242, .6);
	}

	.tab-list {
		flex-grow: 1;
	}

	.tab-list .action {
		box-shadow: 0 1px 1px rgba(85, 161, 242, .6);
	}
</style>
